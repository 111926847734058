import {
  collapseColumn,
  column,
  Css,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridTable,
  SelectField,
  simpleHeader,
  useBreakpoint,
  useSessionStorage,
} from "@homebound/beam";

import { emptyCellDash, priceCell } from "src/components";
import { InvoicePage_BillLineItemsFragment, InvoicePage_TradeLineItemFragment } from "src/generated/graphql-types";
import { TableActions } from "src/routes/layout/TableActions";
import { foldEnum, formatList } from "src/utils";

enum ItemsGroupBy {
  Templates = "template",
  ItemCode = "itemCode",
}

type InvoiceTableProps = {
  lineItems: InvoicePage_BillLineItemsFragment[];
  enableProductConfigPlan: boolean;
};

export function InvoiceTable({ lineItems, enableProductConfigPlan }: InvoiceTableProps) {
  const { mdAndDown: mediumAndSmaller } = useBreakpoint();
  const itemTemplateItems = lineItems.map((li) => li.projectItem.itemTemplateItem).compact();
  const [templatesGroupBy, setTemplatesGroupBy] = useSessionStorage("templatesTable", ItemsGroupBy.ItemCode);
  const hasProratedLines =
    enableProductConfigPlan &&
    lineItems.some((bli) => bli.commitmentLineItem?.primaryBidContractLineItem?.prorations.nonEmpty);

  return (
    <div css={Css.os.mt6.$}>
      <div css={Css.df.jcsb.mb4.fdr.if(mediumAndSmaller).fdc.$}>
        <div css={Css.lgSb.if(mediumAndSmaller).mb2.$}>Completed Item Codes</div>
        <TableActions>
          <div>
            {!hasProratedLines && (
              <SelectField
                compact
                sizeToContent
                label="Group by"
                labelStyle={"inline"}
                options={[
                  ...(itemTemplateItems.nonEmpty ? [{ id: ItemsGroupBy.Templates, name: "Plan and Option" }] : []),
                  { id: ItemsGroupBy.ItemCode, name: "Item Code" },
                ]}
                getOptionValue={(o) => o.id}
                getOptionLabel={(o) => o.name}
                value={templatesGroupBy}
                onSelect={(gb) => gb && setTemplatesGroupBy(gb)}
              />
            )}
          </div>
        </TableActions>
      </div>
      <GridTable
        as="table"
        rows={rows(lineItems, templatesGroupBy, hasProratedLines)}
        columns={columns()}
        style={{ bordered: false, allWhite: true }}
        rowStyles={{ header: { cellCss: Css.gray600.$ }, total: { cellCss: Css.smSb.mt2.$ } }}
      />
    </div>
  );
}

type HeaderRow = { kind: "header" };
type GroupRow = { kind: "group"; data: string | InvoicePage_TradeLineItemFragment[] };
type ItemRow = { kind: "item"; data: InvoicePage_BillLineItemsFragment };
type TotalRow = { kind: "total"; data: number };
export type Row = HeaderRow | GroupRow | ItemRow | TotalRow;

function rows(
  lineItems: InvoicePage_BillLineItemsFragment[],
  groupedBy: ItemsGroupBy,
  hasProratedLines: boolean,
): GridDataRow<Row>[] {
  const groupedItis = hasProratedLines
    ? groupByProratedLines(lineItems)
    : foldEnum(groupedBy, {
        template: groupByTemplate(lineItems),
        itemCode: groupByItemCode(lineItems),
      });
  return [
    simpleHeader,
    ...groupedItis,
    {
      kind: "total" as const,
      id: "total",
      data: lineItems.sum((li) => (li.bill.isTradePartnerCredit ? -li.amountInCents : li.amountInCents)),
    },
  ];
}

function groupByTemplate(lis: InvoicePage_BillLineItemsFragment[]): GridDataRow<Row>[] {
  const groupedbyTemplates = lis.groupBy((li) => li.projectItem.baseOrOptionName);
  return Object.entries(groupedbyTemplates).map(([templateName, clis]) => {
    return {
      kind: "group" as const,
      id: templateName,
      data: templateName,
      pin: templateName.includes("Base") ? { at: "first" as const, filter: true } : undefined,
      children: clis.map((cli) => ({
        kind: "item" as const,
        id: cli.id,
        data: cli,
      })),
    };
  });
}

function groupByItemCode(lis: InvoicePage_BillLineItemsFragment[]): GridDataRow<Row>[] {
  const groupedItems = lis.groupBy((li) => li.projectItem.item.costCode.displayName);
  return Object.entries(groupedItems).map(([itemCode, blis]) => {
    return {
      kind: "group" as const,
      id: itemCode,
      data: blis.first?.projectItem.item.costCode.displayName ?? "-",
      children: blis.map((li) => ({
        kind: "item" as const,
        id: li.id,
        data: li,
      })),
    };
  });
}

function groupByProratedLines(lis: InvoicePage_BillLineItemsFragment[]): GridDataRow<Row>[] {
  // Group by trade line item
  const groupedItems = lis.groupBy(
    (bli) =>
      bli.commitmentLineItem?.primaryBidContractLineItem?.prorations.map((li) => li.tradeLineItem.id).join("") ?? "",
  );
  return Object.entries(groupedItems).map(([tradeId, blis]) => {
    const tradeTotal = blis.sum((li) => (li.bill.isTradePartnerCredit ? -li.amountInCents : li.amountInCents));
    return {
      kind: "group" as const,
      id: tradeId,
      data:
        lis.first?.commitmentLineItem?.primaryBidContractLineItem?.prorations.map((proration) => ({
          ...proration.tradeLineItem,
          totalCostInCents: tradeTotal,
        })) ?? [],
      children: blis.map((li) => ({ kind: "item" as const, id: li.id, data: li })),
    };
  });
}

function columns(): GridColumn<Row>[] {
  return [
    collapseColumn<Row>({ item: emptyCell }),
    column<Row>({
      id: "qty",
      header: "QTY",
      group: (data) => ({
        content: Array.isArray(data) ? formatList(data.map((data) => data.productOffering.displayName)) : data,
        colspan: 3,
      }),
      item: ({ commitmentLineItem }) =>
        commitmentLineItem?.newQuantity || commitmentLineItem?.quantity || emptyCellDash,
      total: emptyCell,
      w: "200px",
    }),
    column<Row>({
      id: "uom",
      header: "UoM",
      group: emptyCell,
      item: ({ projectItem: { itemTemplateItem, unitOfMeasure } }) =>
        itemTemplateItem?.unitOfMeasure.abbreviation.toLowerCase() ?? unitOfMeasure.abbreviation.toLowerCase(), // use pi if no iti
      total: emptyCell,
      w: "100px",
    }),
    column<Row>({
      id: "itemCode",
      header: "Item Code",
      group: emptyCell,
      item: ({ projectItem: { itemTemplateItem, item } }) => itemTemplateItem?.item.fullCode ?? item.fullCode,
      total: emptyCell,
      w: "150px",
    }),
    column<Row>({
      id: "description",
      header: "Description",
      group: emptyCell,
      item: ({ projectItem: { itemTemplateItem, name } }) => itemTemplateItem?.name ?? name,
      total: "Total Amount Due",
      w: "200px",
    }),
    column<Row>({
      id: "unitCost",
      header: "Unit Cost",
      group: emptyCell,
      item: ({ commitmentLineItem }) => {
        const { quantity, newQuantity, newTotalCostInCents, primaryBidContractLineItem } = commitmentLineItem ?? {};
        return commitmentLineItem?.projectItem.unitOfMeasure.useQuantity &&
          !primaryBidContractLineItem?.prorations.nonEmpty
          ? priceCell({ valueInCents: (newTotalCostInCents || 0) / (newQuantity || quantity || 1) })
          : emptyCellDash;
      },
      total: emptyCell,
      w: "140px",
    }),
    column<Row>({
      id: "totalCost",
      header: "Total Cost",
      // Show cost for each trade line item
      // Labor tasks associated with multiple trade line items will have their costs accounted for in each individual trade line
      group: (data) =>
        Array.isArray(data) && data.length === 1 ? priceCell({ valueInCents: data[0].totalCostInCents }) : emptyCell,
      item: ({ amountInCents, bill, commitmentLineItem }) =>
        commitmentLineItem?.primaryBidContractLineItem?.prorations.nonEmpty
          ? emptyCell
          : priceCell({ valueInCents: bill.isTradePartnerCredit ? -amountInCents : amountInCents }),
      total: (data) => priceCell({ valueInCents: data }),
      w: "160px",
    }),
  ];
}
