import { Button } from "@homebound/beam";
import { ReactNode } from "react";
import { useCreatePandaDocSessionMutation } from "src/generated/graphql-types";
import { openNewTab } from "src/utils";

type SignPandaDocButtonProps = {
  label: string;
  disabled?: boolean | string | ReactNode;
  pandaDocId: string;
};

export function SignPandaDocButton({ pandaDocId, ...others }: SignPandaDocButtonProps) {
  const [createPandaDocSession] = useCreatePandaDocSessionMutation();
  return (
    <Button
      {...others}
      variant="primary"
      onClick={async () => {
        // Create the pandadoc session
        const { data } = await createPandaDocSession({ variables: { input: { pandaDocId } } });
        // Opens a new tab and navigates to the pandadoc using the session id
        openNewTab(`https://app.pandadoc.com/s/${data?.createPandaDocSession.sessionId}`);
      }}
    />
  );
}
